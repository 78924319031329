<template>
  <div class="app-link">
    <div class="app-logo">
      <img
        src="https://jsh-oss-static.jsh.com/prod/image/2024/8/20/fe511639-dd17-4226-b100-f859f64f545b.png"
        alt="易理货logo"
      >
    </div>
    <div class="link-info">
      <div class="info-container">
        <div class="info-left">
          <div class="info-title">
            易理货
          </div>
          <div class="info-sub-title">
            专业家电交易管理平台
          </div>
        </div>
        <div class="info-right">
          <img
            src="https://jsh-oss-static.jsh.com/prod/image/2024/8/20/370518bb-4260-4f17-b5b3-4f9ec0bb50c5.png"
            alt="易理货logo"
          >
        </div>
      </div>
    </div>
    <div class="link-container">
      <el-popover placement="right" trigger="click" :append-to-body="false">
        <img :src="yilihuoAppQrCode" alt="">
        <div class="link-button" slot="reference">
          <img
            src="https://jsh-oss-static.jsh.com/prod/image/2024/8/20/c6d93c27-f9f6-436e-8a80-047ef563f5a7.png"
            alt="icon"
          >
          <img
            src="https://jsh-oss-static.jsh.com/prod/image/2024/8/20/1ed947eb-90a0-4089-a697-604a1715419f.png"
            alt="icon"
          >
          下载易理货app
        </div>
      </el-popover>
      <div class="link-button" @click="download">
        <div v-loading="loadingUrl">
          <img
            src="https://jsh-oss-static.jsh.com/prod/image/2024/8/20/638f46f7-36f7-4552-bc0f-9fb851c68759.png"
            alt="icon"
          >
          下载客户端
        </div>
      </div>
      <div class="link-item">
        已安装，<span @click="open">打开</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Popover } from 'element-ui'
import { yilihuoAppQrCode } from '../../utils'
import { CloudBase } from '../../apis'

Vue.use(Popover)

export default {
  name: 'AppLink',
  data () {
    return {
      yilihuoAppQrCode,
      key: this.$route.params.key || '',

      loadingUrl: false,
      downloadUrl: ''
    }
  },
  mounted () {
    const { key } = this
    if (key) {
      this.open()
    } else {
      this.$router.replace('/404')
    }
  },
  methods: {
    open () {
      location.href = `yilihuo://${this.key}`
    },
    async download () {
      if (!this.downloadUrl) {
        this.loadingUrl = true
        await fetch(`${CloudBase.getAppVersionAndModule}?systemVersion=ylh_pc`, { method: 'GET', credentials: 'omit' })
          .then(_ => _.json())
          .then(data => {
            if (data.success) {
              this.downloadUrl = (data.data || [])
                .find(_ => _.moduleCode === 'win32')
                ?.downloadUrl || ''
            } else {
              this.$message.error(data.errorMsg || '网络异常，请稍后再试~')
            }
          })
          .catch(() => {
            this.$message.error('网络异常，请稍后再试~')
          })
          .finally(() => {
            this.loadingUrl = false
          })

        if (!this.downloadUrl) {
          return
        }
      }

      window.open(this.downloadUrl, '_blank', 'noopener noreferrer')
    }
  }
}
</script>

<style lang="scss" scoped>
.app-link {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background:
    // 左边
    url('https://jsh-oss-static.jsh.com/prod/image/2024/8/20/273c912c-8b9a-460a-b2bd-8006730d40ee.png') -2vw -8vh no-repeat,
    // 右边
    url('https://jsh-oss-static.jsh.com/prod/image/2024/8/20/0c089fdf-5858-4a7d-8132-ec08f580899b.png') 50vw 8vh no-repeat,
    // 底色
    radial-gradient(28.07% 19.00% at 59% 23%, rgba(221, 242, 255, 0), rgb(225, 244, 255) 100%);
}

.app-logo {
  position: fixed;
  top: 40px;
  left: 20vw;
}

.link-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 28px;
  width: 100%;
  height: 37.5vh;

  .info-container {
    display: flex;
    align-items: center;

    .info-left {
      margin-right: 48px;
    }
  }

  .info-title {
    color: rgb(48, 49, 51);
    font-size: 32px;
    font-weight: bold;
    line-height: 44px;
  }

  .info-sub-title {
    margin-top: 10px;
    color: rgb(144, 147, 153);
    font-size: 20px;
    line-height: 28px;
  }
}

.link-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgb(255, 255, 255);
  background: linear-gradient(180.00deg, rgba(216, 233, 249, 0.75), rgb(255, 255, 255) 100%);

  .link-button {
    display: flex;
    align-items: center;
    padding: 0 75px;
    margin-top: 24px;
    width: 360px;
    height: 80px;
    color: rgb(48, 49, 51);
    font-size: 16px;
    line-height: 22px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 16px;
    transition: 200ms;
    cursor: pointer;

    &:hover {
      color: #606266;
    }

    img {
      margin-right: 16px;
    }

    div {
      width: 100%;
    }
  }

  .link-item {
    margin-top: 16px;
    color: rgb(48, 49, 51);
    font-size: 16px;
    line-height: 22px;

    span {
      color: rgb(64, 158, 255);
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        color: #66b1ff;
      }
    }
  }

  &::v-deep(.el-popover) {
    min-width: unset;
  }
}
</style>
